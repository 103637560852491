





























import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { Renter } from '@/api/interfaces/renter'
import { QueryParams } from '@/store/api-plateform-utils'

export default defineComponent({
  name: 'SelectSearchRenters',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateRenter: () => import('../dialog/createEditRenter.vue'),
  },
  props: {
    value: {
      type: Object as () => Renter,
      required: false,
      default: () => null,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value && this.value['@id']) {
          this.renterList = [this.value]
        }
      },
    },
  },
  data () {
    return {
      dialogRenter: false,
      renterEdit: {},
      renterList: [] as Renter[],
      searching: false,
    }
  },
  computed: {
    renterName (): string {
      return this.value ? (this.value.name as string) : ''
    },
  },
  methods: {
    ...mapActions('renter', {
      load: 'load',
      post: 'post',
    }),
    itemText (item: Renter) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return item.name
    },
    async loadRenters (searchText: string) {
      const request: QueryParams = {
        page: 1,
        itemsPerPage: 30,
      }
      if (searchText) {
        request.name = searchText
      }
      this.searching = true
      this.renterList = await this.load(request)
      this.searching = false
    },
    onInput (uri: string) {
      const renter = this.findRenterInList(uri)
      this.$emit('input', renter)
    },
    findRenterInList (uri: string) {
      return (this.renterList as Array<Renter>).find(x => x['@id'] === uri)
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    toggleModal () {
      this.dialogRenter = !this.dialogRenter
    },
    editRenter () {
      this.renterEdit = { ...this.value }
      this.toggleModal()
    },
    onClose () {
      this.dialogRenter = false
      this.renterEdit = {}
    },
    onRenterCreated (renter: Renter) {
      this.$emit('input', renter)
    },
    onRenterUpdated (renter: Renter) {
      this.$emit('input', renter)
    },
  },
})
